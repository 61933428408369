import React, { useState, useEffect } from "react"
import { useMatch } from "react-router-dom"
import { Box, Typography, CircularProgress } from "@mui/material"

const Picture = () => {
    const match = useMatch("/picture/:pictureID")
    const [imageLoaded, setImageLoaded] = useState(false)
    const [imageError, setImageError] = useState(false)

    useEffect(() => {
        const img = new Image()
        img.src = `https://api.studiocity.fun/pictures/${match.params.pictureID}`

        img.onload = () => {
            setImageLoaded(true)
        }

        img.onerror = () => {
            setImageError(true)
        }
    }, [match.params.pictureID])

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100%"
            bgcolor="#2a2a2a"
            color="#fff"
            textAlign="center"
            p={3}
        >
            {imageLoaded ? (
                <img
                    alt="play"
                    src={`https://api.studiocity.fun/pictures/${match.params.pictureID}`}
                    style={{ width: "100%", maxWidth: "600px", borderRadius: "8px" }}
                />
            ) : (
                <Box>
                    {imageError ? (
                        <>
                            <Typography variant="h6" gutterBottom>
                                請在遊戲結束後下載照片
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                请在游戏结束后下载照片
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Images will be ready for download after the game
                            </Typography>
                        </>
                    ) : (
                        <>
                            <CircularProgress color="inherit" />
                            <Typography variant="h6" mt={2}>
                                Loading...
                            </Typography>
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default Picture
